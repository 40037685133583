<template>
  <div>
    <v-row class="text-center">
      <v-col cols="6">
        <big-button elevated :title="$t('profile.upload-photo.upload-photo')" :text="$t('profile.upload-photo.upload-photo-description')" icon="fa fa-upload" @click="startFileUpload"/>
      </v-col>
      <v-col cols="6">
        <big-button elevated :title="$t('profile.upload-photo.take-selfie')" :text="$t('profile.upload-photo.take-selfie-description')" icon="fa fa-camera" @click="showSelfieDialog"/>
      </v-col>
    </v-row>
    <input type="file" accept="image/*" ref="file" class="d-none" v-on:change="loadPhotoPreview" />
    <input type="file" accept="image/*" ref="nativeCamera" class="d-none" capture="user" v-on:change="loadNativeCamPreview" />

    <v-dialog
      v-model="selfieDialog"
      fullscreen
      hide-overlay
    >
      <v-card color="black">
        <v-toolbar
          dark
          extension-height="4"
          color="primary"
        >
          <v-btn
            icon
            dark
            class="ml-2"
            @click="selfieDialog = false"
          >
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>{{ cameraMode ? 'Take a Selfie' : 'Upload a photo' }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn v-if="!showPreview && webcam && webcam.webcamList.length > 1" dark icon @click="flipCamera">
              <v-icon class="mr-2">fa-repeat-alt</v-icon>
            </v-btn>
            <!-- <v-btn v-if="!showPreview" dark text @click="takeSelfie">
              <v-icon class="mr-2">fa-camera</v-icon>
              Take selfie
            </v-btn> -->
            <v-btn v-if="showPreview && cameraMode" dark text @click="resumeCamera">
              <v-icon class="mr-2">fa-camera</v-icon>
              {{$t('profile.upload-photo.try-again')}}
            </v-btn>
            <!-- <v-btn v-if="showPreview" dark text @click="saveSelfie">
              <v-icon class="mr-2">fa-upload</v-icon>
              Save
            </v-btn> -->
          </v-toolbar-items>
          <v-progress-linear slot="extension"  v-if="$store.getters.loading" indeterminate color="progress" class="ajax-loader ma-0"></v-progress-linear>
        </v-toolbar>
        <div v-if="debugInfo" style="color:white;">{{debugInfo}}</div>
        <div v-if="cameraMode == 'CAMERA_ERROR'" class="pa-4" style="color:white; text-align: center;">
          <v-icon size="64" color="white" class="my-12">fal fa-exclamation-triangle</v-icon>
          <p>{{$t('profile.upload-photo.error-message')}}</p>
          <v-btn outlined color="white" @click="startCamera">{{$t('shared.retry')}}</v-btn>
          <p class="mt-12">{{$t('profile.upload-photo.error-use-native-cam-message')}}</p>
          <v-btn outlined color="white" @click="startNativeCamera">{{$t('profile.upload-photo.error-start-native-camera')}}</v-btn>
        </div>
        <div :class="(showPreview ? 'd-none' : 'd-flex') + ' justify-center align-center'">
          <v-progress-circular v-if="starting" color="white" class="mt-8" size="150" indeterminate />
          <div :class="starting ? 'd-none' : ''">
            <video id="webcam" ref="video" autoplay playsinline webkit-playsinline class="media-element" v-on:loadedmetadata="metadataLoaded"></video>
          </div>
        </div>
        <div :class="(showPreview ? 'd-flex' : 'd-none') + ' justify-center align-center'">
          <canvas ref="preview" id="canvas" class="d-block media-element"></canvas>
        </div>
        <audio id="snapSound" src="/audio/snap.wav" preload = "auto"></audio>
        <v-text-field
          v-if="showPreview"
          v-model="description"
          solo
          hide-details
          prepend-icon="fa-comment"
          :placeholder="$t('profile.upload-photo.photo-description-placeholder')"
          class="comment-box"
          />
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :class="activeFab.class"
            dark
            absolute
            bottom
            right
            fab
            large
            :loading="starting || $store.getters.isLoading"
             @click="activeFab.click ? activeFab.click() : dummy();"
          >
            <v-icon>{{ activeFab.icon }}</v-icon>
          </v-btn>
        </v-fab-transition>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import webcamEasy from "@/util/webcam-easy.js";
import profileService from "@/services/profileService";
import BigButton from '@/components/BigButton.vue';

export default {
  name: "UploadPhoto",
  components: {
    BigButton,
  },
  props: {
  },
  data() {
    return {
      file: null,
      capturedDataUrl: null,
      webcam: null,
      selfieDialog: false,
      showPreview: false,
      cameraMode: null,
      starting: false,
      debugInfo: null,
      description: null,
      activeFab: {  },
      photoRules: [
        value => !value || value.size < 10 * 1000000 || 'Please select a photo of less than 10 MB.',
      ],
    };
  },
  async mounted() {
  },
  methods: {
    dummy(){

    },
    async sleep(msec) {
      return new Promise(resolve => setTimeout(resolve, msec));
    },
    logEvent(msg) {
      console.log('[photo-picker]', msg);
      //this.$analytics.event('selfie-photo-upload', { 'event_category': 'profile', 'event_label': name });
    },
    async loadData() {
    },
    async reset() {
    },

    async prepareAndStartCamera() {
      await this.prepareCamera();
      await this.startCamera();
      this.logEvent('prepareAndStartCamera');
    },
    async startFileUpload() {
      this.triggerUploadDialog(this.$refs.file);
      this.logEvent('startFileUpload');
    },
    async startNativeCamera() {
      this.triggerUploadDialog(this.$refs.nativeCamera);
      this.logEvent('startNativeCamera');
    },
    async triggerUploadDialog(elem) {
      elem.classList.remove("d-none");;
      elem.focus();
      elem.click();
      elem.classList.add("d-none");;
    },
    async loadNativeCamPreview() {
      this.file = this.$refs.nativeCamera.files[0];
      this.loadPreview();
    },
    async loadPhotoPreview() {
      this.file = this.$refs.file.files[0];
      this.loadPreview();
    },
    async loadPreview() {
      if (this.file) {
        this.cameraMode = null;
        this.selfieDialog = true;
        this.showPreview = true;
        this.starting = false;
        this.$nextTick(() => {
          var img = new Image();
          var canvas = this.$refs.preview;
          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img,0,0);
          };
          this.capturedDataUrl = URL.createObjectURL(this.file);
          console.log('preview', this.capturedDataUrl);
          img.src = this.capturedDataUrl;
          this.logEvent('loadPreview');
          //this.$refs.preview.src = URL.createObjectURL(this.file);
        });
      }
    },
    async showSelfieDialog() {
      this.cameraMode = 'STARTING';
      this.selfieDialog = true;
    },
    async prepareCamera() {
      if (!this.webcam) {
        this.starting = true;
        const webcamElement = document.getElementById('webcam');
        const canvasElement = document.getElementById('canvas');
        const snapSoundElement = document.getElementById('snapSound');
        try {
          this.webcam = new webcamEasy(webcamElement, 'user', canvasElement, snapSoundElement);
        }
        catch (ex) {
          console.log("webcam couldn't prepare");
          this.debugInfo = 'Error preparing: ' + ex.message;
        }
      }
    },
    async metadataLoaded() {
      //this.debugInfo = `Video ${this.$refs.video.videoWidth} x ${this.$refs.video.videoHeight}`;
    },
    async startCamera() {
      try {
        this.starting = true;
        //throw 'Test';
        await this.webcam.start();
        this.starting = false;
        console.log("webcam started", this.webcam.webcamList);
      }
      catch (ex) {
        this.starting = false;
        if (ex.message === 'Could not start video source' && this.cameraMode !== 'RETRY_START') {
          this.cameraMode = 'RETRY_START';
          // sometimes a restart helps;
          await this.sleep(1000);
          this.startCamera();
        }
        else {
          this.cameraMode = 'CAMERA_ERROR';
          this.debugInfo = 'Error starting: ' + ex.message;
        }
        console.error(ex, "webcam couldn't start");
      }
    },
    async takeSelfie() {
      let picture = this.webcam.snap();
      this.webcam.stop();  
      this.showPreview = true;
      this.capturedDataUrl = picture;
      this.logEvent('takeSelfie');
      /*this.$nextTick(() => {
        this.$refs.preview.src = picture;
      });*/
      //document.querySelector('#download-photo').href = picture;
    },
    async resumeCamera() {
      this.showPreview = false;
      this.starting = true;
      this.$nextTick(async () => {
        await this.startCamera();  
      });
    },
    async flipCamera() {
      if (this.webcam) {
        this.webcam.stop();  
        this.webcam.flip();
        await this.webcam.start();  
        this.logEvent('flipCamera');
      }
    },
    async stopCamera() {
      if (this.webcam) {
        this.webcam.stop();
      }
    },
    async saveSelfie() {
      var formData = new FormData();
      if (this.file) {
        formData.append("file", this.file);
      }
      else {
        var blob = this.dataURItoBlob(this.capturedDataUrl);
        formData.append("file", blob, "photo.jpg");
      }
      formData.append("description", this.description || '');
      if (this.$listeners && this.$listeners.submit) {
        this.logEvent('saveSelfie');
        this.$emit('submit', formData);
        this.selfieDialog = false;
        this.description = null;
        return;
      }
      var response = await this.$helpers.emitPromised(this, 'upload', formData);
      this.$helpers.toastResponse(this, response.data, this.$t('profile.upload-photo.photo-uploaded-successfully'));
      this.logEvent('saveSelfie');
      if (response.data.status == 'OK') {
        this.selfieDialog = false;
        this.description = null;
      }
    },
    dataURItoBlob(dataURI) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      array.length=binary.length;
      for(var i = 0; i < binary.length; i++) {
          array[i]=binary.charCodeAt(i);
      }
      return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
    }
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
  },
  watch: {
    async selfieDialog(visible) {
      if (visible) {
        console.log('setting active FAB to selfie');
        this.activeFab = { key: 'selfie', class:'capture-fab', color: 'red', icon: 'fa-scrubber', click: this.takeSelfie };
      }
      if (visible && this.cameraMode) {
        this.$nextTick(async () => {
          await this.prepareAndStartCamera();
        });
      } else if (!visible) {
        if (this.cameraMode) {
          await this.stopCamera();
        }
        this.showPreview = false;
        this.capturedDataUrl = null;
      }
    },
    async showPreview(value) {
      if (value) {
        console.log('setting active FAB to preview');
        this.activeFab = { key: 'preview', class:'save-fab', color: 'primary', icon: 'fa-check', click: this.saveSelfie };
      }
      else {
        this.activeFab = { key: 'selfie', class:'capture-fab', color: 'red', icon: 'fa-scrubber', click: this.takeSelfie };
      }
    }
  },

};
</script>
<style lang="scss" >
  .v-toolbar__extension { padding: 0 !important; }
  .media-element {max-width:100vw; max-height:calc(100vh - 56px);}
  .comment-box {
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 100px;

    .v-icon { color: white !important; }
  }
  .v-btn--fab.v-size--large.v-btn--absolute.v-btn--bottom { bottom: 20px !important;  transition: right 0.5s ease 0s; }
  .save-fab { right: 20px !important; }
  .capture-fab { right: calc(50% - 32px) !important; }
</style>

